import { useState, useEffect, useRef } from "react";
import CustomLink from "./CustomLink";
import ImageComponent from "./shared/Image";
import { languageGlobeImg } from "@/utils/image";
import styles from "@/styles/languageDropdown.module.scss";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

interface LanguageDropdownProps {
  dropWidth?: number;
  isFixedTop?: boolean;
}

const LanguageDropdown = ({ dropWidth, isFixedTop }: LanguageDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropBoxRef = useRef<HTMLDivElement>(null);
  const dropListRef = useRef<HTMLUListElement>(null);
  const location = useLocation();

  const isJapanese = location.pathname.includes("ja");

  const dropClose = () => {
    if (dropWidth && window.innerWidth < dropWidth + 1) {
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropBoxRef.current &&
        !dropBoxRef.current.contains(event.target as Node)
      ) {
        dropClose();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (dropWidth && window.innerWidth > dropWidth) {
        dropListRef.current?.removeAttribute("style");
        return;
      }

      if (dropListRef.current && dropListRef.current.style.display) {
        dropListRef.current.style.display = "none";
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dropWidth]);

  return (
    <div
      className={classNames(
        styles.headerLang,
        isOpen && styles.open,
        isFixedTop && styles.topFixedHeaderLang,
      )}
      ref={dropBoxRef}
      onMouseLeave={dropClose}
    >
      <button className={styles.langOpenBtn} onClick={handleClick}>
        <ImageComponent
          className={styles.white}
          src={languageGlobeImg.white}
          alt="아이콘"
        />
        <ImageComponent
          className={styles.blue}
          src={languageGlobeImg.blue}
          alt="아이콘"
        />
        <ImageComponent
          className={styles.black}
          src={languageGlobeImg.black}
          alt="아이콘"
        />
      </button>
      <ul
        id={styles.headerLangList}
        className={styles.langList}
        ref={dropListRef}
      >
        <li className={classNames(!isJapanese && styles.cur)}>
          <a href={import.meta.env.VITE_SITE_URL}>KR</a>
        </li>
        <li className={classNames(isJapanese && styles.cur)}>
          <a href={`${import.meta.env.VITE_SITE_URL}/ja`}>JP</a>
        </li>
      </ul>
    </div>
  );
};

export default LanguageDropdown;
