import { BrowserRouter, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import "./App.scss";

import Layout from "@/components/layout/Layout";
import { HelmetProvider } from "react-helmet-async";
import useBrowserLanguage from "@/hooks/useBrowserLanguage";
import useViewPort from "@/hooks/useViewport";
import useDetectDevice from "@/hooks/useDetectDevice";
import HomePage from "./pages/Home";
import LoadingIcon from "./components/Loading";
import NotFound from "./pages/NotFound";

const PartnerPage = lazy(() => import("@/pages/Partner"));
const H265DevicePage = lazy(() => import("@/pages/H265Device"));
const H264DevicePage = lazy(() => import("@/pages/H264Device"));
const FaqPage = lazy(() => import("@/pages/Faq"));
const OnlineInquiryPage = lazy(() => import("@/pages/OnlineInquiry"));
const H265DetailPage = lazy(() => import("@/pages/H265"));
const V4Page = lazy(() => import("@/pages/V4"));

function App() {
  useBrowserLanguage();
  useViewPort();
  useDetectDevice();

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Suspense fallback={<LoadingIcon />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="partner" element={<PartnerPage />} />
              <Route path="h265-device" element={<H265DevicePage />} />
              <Route path="h264-device" element={<H264DevicePage />} />
              <Route path="faq" element={<FaqPage />} />
              <Route path="online-inquiry" element={<OnlineInquiryPage />} />
              <Route path="h265" element={<H265DetailPage />} />
              <Route path="v4" element={<V4Page />} />
            </Route>
            <Route path="/ja" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="partner" element={<PartnerPage />} />
              <Route path="h265-device" element={<H265DevicePage />} />
              <Route path="h264-device" element={<H264DevicePage />} />
              <Route path="faq" element={<FaqPage />} />
              <Route path="online-inquiry" element={<OnlineInquiryPage />} />
              <Route path="h265" element={<H265DetailPage />} />
              <Route path="v4" element={<V4Page />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
