import { useEffect } from "react";
import useLanguageStore from "@/store/language";

const useBrowserLanguage = () => {
  const { setLanguage } = useLanguageStore();

  useEffect(() => {
    const getInitialLanguage = () => {
      const pathLang = window.location.pathname.split("/")[1];
      if (pathLang === "ja") {
        return "jp";
      }

      const lang = navigator.language || navigator.languages[0];
      if (lang.startsWith("ko")) {
        return "ko";
      } else if (lang.startsWith("jp") || lang.startsWith("ja")) {
        return "jp";
      }
      return "ko";
    };

    const language = getInitialLanguage();
    setLanguage(language);
  }, [setLanguage]);
};

export default useBrowserLanguage;
