import styles from "@/styles/notfound.module.scss";
import CustomLink from "@/components/CustomLink";
import { useTranslation } from "react-i18next";
import useLanguageFromPath from "@/hooks/useLanguageFromPath";

const NotFound = () => {
  const { t } = useTranslation();
  useLanguageFromPath();

  return (
    <div className={styles.notFoundContainer}>
      <h1>404</h1>
      <p>{t("NOT_FOUND_TEXT")}</p>
      <CustomLink to="/">{t("GO_HOME")}</CustomLink>
    </div>
  );
};

export default NotFound;
