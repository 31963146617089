import IconComponent from "./shared/Icon";
import styles from "@/styles/loadingIcon.module.scss";

function LoadingIcon() {
  return (
    <div className={styles.loadingWrapper}>
      <IconComponent
        iconName="loading"
        iconAlt="로딩이미지"
        className={styles.loadingIconImg}
      />
    </div>
  );
}

export default LoadingIcon;
