import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import i18n from "@/locales/i18n";
import useLanguageStore from "@/store/language";

export default function useLanguageFromPath() {
  const location = useLocation();
  const { language, setLanguage } = useLanguageStore();

  useEffect(() => {
    const newLang = location.pathname.startsWith("/ja") ? "jp" : "ko";
    document.documentElement.lang = newLang;
    // ✅ 이미 설정된 언어와 다르면 변경
    if (i18n.language !== newLang) {
      i18n.changeLanguage(newLang);
    }

    if (language !== newLang) {
      setLanguage(newLang);
    }
  }, [location.pathname, language, setLanguage]);
}
