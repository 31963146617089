import { Link, LinkProps, useLocation } from "react-router-dom";

export interface CustomLinkProps extends LinkProps {
  preserveJa?: boolean;
}

export default function CustomLink({
  to,
  preserveJa = true,
  ...props
}: CustomLinkProps) {
  const location = useLocation();
  const isJapanesePath = location.pathname.startsWith("/ja");

  let finalPath: string;

  if (typeof to === "string") {
    finalPath = to;
  } else if (
    to &&
    typeof to === "object" &&
    "pathname" in to &&
    typeof to.pathname === "string"
  ) {
    finalPath = to.pathname;
  } else {
    finalPath = "/";
  }

  if (finalPath === "/" || finalPath === "/ja") {
    if (isJapanesePath) {
      finalPath = "/ja";
    }

    return <Link to={finalPath} {...props} />;
  }

  // ✅ "/partner"와 같은 일반 경로 처리
  if (preserveJa && isJapanesePath) {
    if (!finalPath.startsWith("/ja")) {
      finalPath = `/ja${finalPath.startsWith("/") ? finalPath : `/${finalPath}`}`;
    }
  } else if (!preserveJa) {
    finalPath = finalPath.replace(/^\/ja/, "") || "/";
  }

  return <Link to={finalPath} {...props} />;
}
